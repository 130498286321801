var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "lineManagement", staticClass: "line-management" },
    [
      _c("div", { ref: "functionButtons", staticClass: "function-buttons" }),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                model: _vm.form,
                "label-position": "left",
                "label-width": "72px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "线路名称：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-width",
                      attrs: {
                        multiple: "",
                        filterable: "",
                        remote: "",
                        "collapse-tags": "",
                        clearable: "",
                        placeholder: "请输入线路名称",
                        "remote-method": _vm.remoteMethod,
                        loading: _vm.loading,
                        "multiple-limit": 20,
                        "reserve-keyword": false
                      },
                      model: {
                        value: _vm.form.companyIds,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "companyIds", $$v)
                        },
                        expression: "form.companyIds"
                      }
                    },
                    _vm._l(_vm.options, function(item) {
                      return _c("el-option", {
                        key: item.companyId,
                        attrs: {
                          label: item.companyName,
                          value: item.companyId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所在区域：" } },
                [
                  _c("el-cascader", {
                    attrs: { options: _vm.cityList, clearable: "" },
                    model: {
                      value: _vm.form.city,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "city", $$v)
                      },
                      expression: "form.city"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "线路类型：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择线路类型" },
                      model: {
                        value: _vm.form.lineType,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "lineType", $$v)
                        },
                        expression: "form.lineType"
                      }
                    },
                    _vm._l(_vm.lineTypeList, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: { label: item.dictValue, value: item.dictCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "0" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.searchLoading
                      },
                      on: { click: _vm.onSearch }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, stripe: "", height: _vm.tableHeight } },
        [
          _c("el-table-column", {
            attrs: { label: "序号", type: "index", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "parentName", label: "所属公司" }
          }),
          _c("el-table-column", {
            attrs: { prop: "companyName", label: "线路名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "cityStr", label: "所在城市" }
          }),
          _c("el-table-column", {
            attrs: { prop: "lineType", label: "线路类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.lineTypeObj[Number(scope.row.lineType)]) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "captainName", label: "路队长" }
          }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.status === 0 ? "正常" : "停运") +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.nowMenuList.indexOf("编辑") >= 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.onUpdateLine(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    (scope.row.status === 0
                    ? _vm.nowMenuList.indexOf("停运") >= 0
                    : _vm.nowMenuList.indexOf("启用") >= 0)
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort:
                                scope.row.status === 0 ? "danger" : "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.onDisableLine(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.status === 0 ? "停运" : "启用")
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.nowMenuList.indexOf("例检设置") >= 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.onSettingLine(scope.row)
                              }
                            }
                          },
                          [_vm._v("例检设置")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "current-change": _vm.onCurrentChange,
              "size-change": _vm.onSizeChange
            }
          })
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogVisible,
                width: "70%",
                "custom-class": "add-line-dialog"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                },
                close: _vm.onDialogClose
              }
            },
            [
              _c("addLine", {
                ref: "addLine",
                attrs: { itemId: _vm.itemId },
                on: { close: _vm.onDialogClose, onLoading: _vm.onLoading }
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.editLoading
                      },
                      on: { click: _vm.onSave }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.settingdialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "例检设置",
                visible: _vm.settingdialogVisible,
                width: "30%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.settingdialogVisible = $event
                },
                close: _vm.onDialogSettingClose
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticClass: "demo-form dialog-form",
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.rules,
                    "label-width": "102px",
                    "label-position": "left"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "线路名称：" } },
                    [
                      _c("el-input", {
                        attrs: { type: "text", disabled: "" },
                        model: {
                          value: _vm.addForm.companyName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.addForm,
                              "companyName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addForm.companyName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "是否例检：", prop: "routineCheckState" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.addForm.routineCheckState,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "routineCheckState", $$v)
                            },
                            expression: "addForm.routineCheckState"
                          }
                        },
                        _vm._l(_vm.routineCheckStateList, function(item) {
                          return _c("el-option", {
                            key: item.dictCode,
                            attrs: {
                              label: item.dictValue,
                              value: item.dictCode
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "例检规则：",
                        prop:
                          _vm.addForm.routineCheckState == 1
                            ? "routineCheckRuleId"
                            : ""
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.addForm.routineCheckRuleId,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "routineCheckRuleId", $$v)
                            },
                            expression: "addForm.routineCheckRuleId"
                          }
                        },
                        _vm._l(_vm.checkRuleList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.ruleName, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: { click: _vm.onDialogSettingClose }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.editLoading
                      },
                      on: { click: _vm.onSaveCheck }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }