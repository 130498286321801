<template>
  <div class="body add-line">
    <div class="form">
      <el-form
        :model="addForm"
        :rules="rules"
        ref="addForm"
        label-width="102px"
        label-position="left"
        class="demo-form dialog-form dialog-form-inline"
      >
        <el-form-item label="线路名称：" prop="companyName">
          <el-input
            type="text"
            v-model.trim="addForm.companyName"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="线路类型：" prop="lineType">
          <el-select
            v-model="addForm.lineType"
            clearable
            placeholder="请选择线路类型"
          >
            <el-option
              v-for="item in lineTypeList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="线路编码：" prop="lineCode">
          <el-input type="text" v-model.trim="addForm.lineCode"></el-input>
        </el-form-item>
        <el-form-item label="所在城市：" prop="city">
          <el-cascader
            :options="cityList"
            clearable
            v-model="addForm.city"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="主场：" prop="masterPark">
          <selectYard
            @getData="getMainYard"
            :city="addForm.city"
            :choosedYard="choosedMaster"
          ></selectYard>
        </el-form-item>
        <el-form-item label="副场：" prop="slaverPark">
          <selectYard
            @getData="getViceYard"
            :city="addForm.city"
            :choosedYard="choosedSlaver"
          ></selectYard>
        </el-form-item>
        <!-- 公交2.0新增路队长 -->
        <el-form-item label="路队长：" prop="captainId">
          <el-select
            v-model="addForm.captainId"
            clearable
            filterable
            placeholder="请选择路队长"
          >
            <el-option
              v-for="item in uerList"
              :label="item.userName"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投诉电话：" prop="complaintTel">
          <el-input
            type="text"
            v-model.trim="addForm.complaintTel"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="客服电话：" prop="serviceTel">
          <el-input
            type="text"
            clearable
            v-model.trim="addForm.serviceTel"
          ></el-input>
        </el-form-item>
      </el-form>
      <p class="from-title form-title2 styleflex">线路站点设置</p>
      <el-button
        type="primary"
        size="small"
        @click="changeLine"
        v-show="addForm.lineType !== 1"
        >切换上下行</el-button
      >
      <div class="line-container">
        <div class="up-down-line">
          <!-- <p class="from-title form-title3">{{addForm.lineType === 1?'下行':'上行'}}</p>       -->
          <!-- <p class="from-title form-title3">上行</p> -->
          <div class="line-time">
            <div class="from-title form-title3">上行</div>
            <div class="time-pick">
              <div class="label">运行时间：</div>
              <el-time-picker
                is-range
                v-model="addForm.upTime"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                format="HH:mm"
                value-format="HH:mm"
              >
              </el-time-picker>
            </div>
          </div>
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>选择站点：</span>
              <selectStation
                @getData="getUpStation"
                :city="addForm.city"
              ></selectStation>
            </div>
            <div ref="container" class="tag-box">
              <div class="tag-section" v-if="upStationList.length > 0">
                <el-tag
                  v-for="(tag, index) in upStationList"
                  v-dragging="{ item: tag, list: upStationList, group: 'tag1' }"
                  :key="index"
                  closable
                  @close="removeUpStation(index)"
                >
                  {{ index + 1 }}.&nbsp;{{ tag.stationName }}
                </el-tag>
              </div>
              <div v-else class="tag-section">暂无数据</div>
            </div>
          </el-card>
        </div>
        <div
          v-show="addForm.lineType !== 1"
          class="up-down-line"
          style="margin-left: 2%"
        >
          <!-- <p class="from-title form-title3" v-show="addForm.lineType !== 1">
            下行
          </p> -->
          <div class="line-time">
            <div class="from-title form-title3">下行</div>
            <div class="time-pick">
              <div class="label">运行时间：</div>
              <el-time-picker
                is-range
                v-model="addForm.downTime"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                format="HH:mm"
                value-format="HH:mm"
              >
              </el-time-picker>
            </div>
          </div>
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>选择站点：</span>
              <selectStation
                @getData="getDownStation"
                :city="addForm.city"
              ></selectStation>
            </div>
            <div ref="container" class="tag-box">
              <div class="tag-section" v-if="downStationList.length > 0">
                <el-tag
                  v-for="(tag, index) in downStationList"
                  v-dragging="{
                    item: tag,
                    list: downStationList,
                    group: 'tag',
                  }"
                  :key="index"
                  closable
                  @close="removeDownStation(index)"
                >
                  {{ index + 1 }}.&nbsp;{{ tag.stationName }}
                </el-tag>
              </div>
              <div v-else class="tag-section">暂无数据</div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  checkLandLine,
} from "@/common/utils/index.js";
import { queryAreaList } from '@/api/lib/api.js'
import {
  saveBusLine,
  queryBusLineById,
  queryUserByCompanyIds
} from '@/api/lib/bus-api.js'
import selectYard from "./select.vue"
import selectStation from "./selectStation.vue"

export default {
  components: {
    selectYard,
    selectStation
  },
  props: {
    itemId: {
      type: [String, Number],
      required: true
    }
  },


  data () {
    return {
      cityList: [],
      addForm: {
        companyName: null,
        lineType: null,
        lineCode: null,
        city: [],
        masterPark: null,
        slaverPark: null,
        upList: [],
        downList: [],
        captainId: null,
        complaintTel: "",
        serviceTel: "",
        upTime: null,
        downTime: null,
      },

      lineTypeList: [
        { dictValue: "单向环线", dictCode: 1 },
        { dictValue: "双向线路", dictCode: 2 },
        { dictValue: "双向环线", dictCode: 3 }
      ],

      rules: {
        companyName: [{ required: true, message: "请输入线路名称", trigger: "blur" }],
        lineType: [{ required: true, message: "请选择线路类型", trigger: "blur" }],
        lineCode: [{ required: true, message: "请输入线路编码", trigger: "blur" }],
        city: [{ required: true, type: 'array', message: "请选择所在城市", trigger: "change" }],
        masterPark: [{ required: true, message: "请输入主场", trigger: "blur" }],
        slaverPark: [{ required: true, message: "请输入副场", trigger: "blur" }],
        // captainId: [{ required: true, message: "请选择路队长", trigger: "blur" }],
        complaintTel: [{ validator: checkLandLine, trigger: "blur" }],
        serviceTel: [{ validator: checkLandLine, trigger: "blur" }],
      },
      loading: false,
      upStationList: [],
      downStationList: [],

      choosedMaster: '',
      choosedSlaver: '',

      uerList: [],

    };
  },

  methods: {
    getUserList () {
      //{companyId:this.addForm.companyId}
      queryUserByCompanyIds().then(res => {
        if (res.code === 1000) {
          this.uerList = res.data || [];
        }
      })
    },
    //切换上下行
    changeLine () {
      let newArr = [];
      newArr = [...this.downStationList];
      this.downStationList = [...this.upStationList];
      this.upStationList = [...newArr];
    },
    // 新增/修改
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (this.upStationList.length == 0) {
            this.$message.warning("请选择上行站点")
            return
          }
          if (this.addForm.lineType !== 1) {
            if (this.downStationList.length == 0) {
              this.$message.warning("请选择下行站点")
              return
            }
            this.$emit('onLoading', true)
            this.downStationList.map((item, i) => {
              item.companyId = this.addForm.companyId
              item.serial = i + 1
              item.moveType = 1
              item.stationType = 1
              if (i === 0) item.stationType = 0
              if (i === this.downStationList.length - 1) item.stationType = 2

            })
            this.addForm.downList = [...this.downStationList]
            this.saveBusLine()

          } else {
            // 单线线路
            this.$emit('onLoading', true)
            // this.saveBusLine()
            this.upStationList.map((item, i) => {
              item.companyId = this.addForm.companyId
              item.serial = i + 1
              item.moveType = 2
              item.stationType = 1
              if (i === 0) item.stationType = 0
              if (i === this.upStationList.length - 1) item.stationType = 2
            })
            this.addForm.downList = [...this.upStationList];
            let downTime = null;
            const data = { ...this.addForm }
            this.addForm.downTime && (data.downTime = this.formatRangeTime(this.addForm.downTime));
            saveBusLine(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    /**保存时 上下行运行时间*/
    formatRangeTime (time) {
      return `${time[0]}-${time[1]}`
    },
    saveBusLine () {
      this.upStationList.map((item, i) => {
        item.companyId = this.addForm.companyId
        item.serial = i + 1
        item.moveType = 1
        item.stationType = 1
        if (i === 0) item.stationType = 0
        if (i === this.upStationList.length - 1) item.stationType = 2
      })
      this.addForm.upList = [...this.upStationList];
      let upTime = null; let downTime = null;
      const data = { ...this.addForm };
      this.addForm.upTime && (data.upTime = this.formatRangeTime(this.addForm.upTime));
      this.addForm.downTime && (data.downTime = this.formatRangeTime(this.addForm.downTime));

      saveBusLine(data).then((res) => {
        if (res.code === 1000) {
          this.$message.success('修改成功')
          this.clsoeDia(res.code === 1000)
        } else {
          this.$message.error(res.msg)
          this.$emit('onLoading', false)
        }
      })
    },
    /** 取消 */
    clsoeDia (flag) {
      this.$emit('close', flag)
    },
    //主场
    getMainYard (value) {
      this.addForm.masterPark = value
    },
    //副场
    getViceYard (value) {
      this.addForm.slaverPark = value
    },
    //上行站点
    getUpStation (value) {
      if (value) {
        this.upStationList.push(value)
      }
    },
    //下行站点
    getDownStation (value) {
      if (value) {
        this.downStationList.push(value)
      }
    },
    //移除上行站点
    removeUpStation (index) {
      this.upStationList.splice(index, 1)

    },
    //移除下行站点
    removeDownStation (index) {
      this.downStationList.splice(index, 1)

    },

    // 查询省市接口
    getAreaList () {
      queryAreaList().then((res) => {
        if (res.code === 1000) {
          this.cityList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  },

  created () {
    this.getAreaList();
  },
  mounted () {
    queryBusLineById({ lineId: this.itemId }).then((res) => {
      if (res.code === 1000) {
        let { detailId, companyId, companyName, lineType, lineCode, city, masterPark, slaverPark, upList, downList, masterName, slaverName, captainId, upTime, downTime,complaintTel,serviceTel } = res.data
        this.addForm = { detailId, companyId, companyName, lineType, lineCode, city, masterPark, slaverPark, captainId, upTime: null, downTime: null,complaintTel,serviceTel };
        if (downTime) {
          this.addForm.downTime = downTime.split("-")
        }
        if (upTime) {
          this.addForm.upTime = upTime.split("-")
        }
        this.getUserList();//获取路队长用户列表
        if (upList && upList.length > 0) {
          upList.map(item => {
            let { stationId, stationName } = item
            this.upStationList.push({ stationId, stationName })
          })
          // console.log(6666,this.upStationList)
        }
        if (downList && downList.length > 0) {
          downList.map(item => {
            let { stationId, stationName } = item
            //区分单线和双线
            // if(lineType===1){
            //   this.upStationList.push({ stationId, stationName })
            // }else{
            //   this.downStationList.push({ stationId, stationName })
            // }
            this.downStationList.push({ stationId, stationName })
          })
        }
        this.choosedMaster = masterName
        this.choosedSlaver = slaverName

      } else {
        this.$message.error(res.msg)
      }
    })
    // this.$dragging.$on('dragged', ({value}) => {
    //   console.log(2222,value)

    // })
    // this.$dragging.$on('dragend', (res) => {
    //     console.error(125454,res);
    // })

  }
};
</script>

<style lang="scss" scoped>
.add-line {
  @include themify() {
    .line-container {
      display: flex;
      justify-content: space-between;
      .up-down-line {
        flex: 1;
      }
      .line-time {
        display: flex;
        // align-items:center;
        .time-pick {
          display: flex;
          align-items: center;
          margin-left: 40px;
          /deep/ .el-date-editor.el-input,
          .el-date-editor.el-input__inner {
            width: 240px;
          }
          .label {
            margin-right: 16px;
          }
        }
      }
    }
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin-bottom: 10px;
    }
    .form-title2 {
      display: flex;
      justify-content: space-between;
    }
    .form-title3 {
      margin: 15px 0;
      display: flex;
      justify-content: space-between;
    }
    .styleflex {
      display: inline-block;
      margin-right: 20px;
    }
    .tag-box {
      overflow: auto;
      .tag-section {
        // display: flex;
        // justify-content: flex-start;
        // align-items: center;
        // flex-wrap: wrap;
        .el-tag {
          width: 55%;
          margin-right: 1%;
          margin-bottom: 10px;
          overflow: hidden;
          display: block;
        }
      }
    }
  }
}
</style>
